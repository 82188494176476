import React, { useEffect } from 'react';
import * as pdfobject from 'pdfobject';
import PropTypes from 'prop-types';

const PDF = ({ containerId, height, pdfOpenParams, url, width }) => {
	useEffect(() => {
		pdfobject.embed(url, `#${containerId}`, {
			height,
			pdfOpenParams,
			width,
		});

		return () => {
			document.body.removeChild(document.getElementById(containerId));
		};
	}, []);

	return <div id={containerId} />;
};

PDF.defaultProps = {
	containerId: 'pdfobject',
	height: '100%',
	pdfOpenParams: {},
	url: '',
	width: '100%',
};

PDF.propTypes = {
	containerId: PropTypes.string,
	height: PropTypes.string,
	pdfOpenParams: PropTypes.object,
	url: PropTypes.string,
	width: PropTypes.string,
};

export default PDF;
