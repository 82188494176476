import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Form from '../components/form';
import Framework from '../components/framework';
import Input from '../components/form/input';
import PDF from '../components/framework/pdf';
import Seo from '../components/framework/seo';
import Submit from '../components/form/submit';
import withLocation from '../middleware/withLocation';

const PrintPage = ({ location }) => {
	const { t } = useTranslation('print');
	const [requestAccess, setRequestAccess] = useState(false);
	const [pdfFile, setPdfFile] = useState();

	const handleCodeSubmit = async (values, { resetForm }) => {
		let url =
			'https://europe-west1-testmottagningen.cloudfunctions.net/result-requestAccess';

		setRequestAccess(true);
		try {
			const requestAccess = await fetch(
				`${url}?patient_id=${values.patient_id}`
			);
			const result = await requestAccess.json();
			if (result.status === 'ok') {
				setRequestAccess(true);
			} else {
				setRequestAccess('error');
			}
		} catch (error) {
			setRequestAccess('error');
		}

		resetForm();
	};

	const handleGetSubmit = async (values, { resetForm }) => {
		setPdfFile(
			`https://europe-west1-testmottagningen.cloudfunctions.net/result-pdf?auth=${values.auth_code}&patient_id=${values.patient_id}`
		);
		resetForm();
	};

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-10">
						<h1 className="h1 title text-muted">{t('t1')}</h1>

						{!pdfFile && (
							<div className="gx-6 row mt-5">
								<div className="col-6">
									<p>{t('t2')}</p>
									<Form
										initialValues={{
											auth_code: '',
											patient_id: '',
										}}
										onSubmit={handleGetSubmit}
									>
										<Input
											id="patient_id"
											name="patient_id"
											placeholder={t('t3')}
											required
											type="text"
										/>
										<Input
											id="auth_code"
											name="auth_code"
											placeholder={t('t4')}
											required
											type="text"
										/>
										<Submit name={t('t5')} />
									</Form>
								</div>
								<div className="col-6">
									<p>{t('t6')}</p>

									<Form
										initialValues={{
											patient_id: '',
										}}
										onSubmit={handleCodeSubmit}
									>
										<Input
											id="patient_id"
											name="patient_id"
											placeholder={t('t7')}
											required
											type="text"
										/>

										{requestAccess && (
											<div className="alert alert-info" role="alert">
												{t('t8')}
											</div>
										)}

										<Submit name={t('t9')} />
									</Form>
								</div>
							</div>
						)}

						{pdfFile && (
							<PDF
								height="600px"
								pdfOpenParams={{
									forceIframe: 'true',
									forcePDFJS: 'true',
									pagemode: 'none',
									view: 'FitH',
								}}
								url={pdfFile}
							/>
						)}
					</div>
				</div>
			</div>
		</Framework>
	);
};

PrintPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(PrintPage);

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					data
					language
					ns
				}
			}
		}
	}
`;
